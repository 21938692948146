<template>
  <div>
    <van-nav-bar title="业务入口" left-text="返回" left-arrow @click-left="back()" />
  </div>
  <van-search v-model="value" placeholder="请输入搜索关键词" />
  <div class="d_form">
    <div class="all">
      <div class="table-container">
        <table class="wide-table2">
          <tr>
            <th>序号</th>
            <th>业务名称</th>
            <th>起始仓库</th>
            <th>目标仓库</th>
            <th>触发方式</th>
            <th>触发逻辑</th>
          </tr>
          <tr v-for="item in list" :key="item.num" @click="goDetail(item)">
            <td>{{ item.num }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.start }}</td>
            <td>{{ item.end }}</td>
            <td>{{ item.option }}</td>
            <td>{{ item.way }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useRouter } from "vue-router"
import { Toast } from 'vant';
import { ref } from 'vue'
const router = useRouter()
const active = ref(0)
const list = ref([
  {
    num: 1,
    name: '业务名称1',
    start: '仓库1',
    end: '仓库2',
    option: '库位状态',
    way: '拉动',
  },
  {
    num: 2,
    name: '业务名称2',
    start: '仓库1',
    end: '仓库2',
    option: '库位状态',
    way: '推动',
  },
  {
    num: 3,
    name: '业务名称1',
    start: '仓库1',
    end: '仓库2',
    option: '订单',
    way: '拉动',
  },
  {
    num: 4,
    name: '业务名称1',
    start: '仓库1',
    end: '仓库2',
    option: '订单',
    way: '推动',
  },
  {
    num: 5,
    name: '业务名称1',
    start: '仓库1',
    end: '仓库2',
    option: '扫描',
    way: '拉动',
  },
  {
    num: 6,
    name: '业务名称1',
    start: '仓库1',
    end: '仓库2',
    option: '扫描',
    way: '拉动',
  },
  {
    num: 7,
    name: '业务名称1',
    start: '仓库1',
    end: '仓库2',
    option: '钢托号',
    way: '拉动',
  },
  {
    num: 8,
    name: '业务名称1',
    start: '仓库1',
    end: '仓库2',
    option: '钢托号',
    way: '推动',
  }
]);
const list1 = ref([
  {
    num: 1,
    name: '后视镜',
    code: 'wu-05',
    amount: '10',
    status: '空',
    lot: '1',
    warehouse: '仓库B',
    unit: '个',
    time: '2023-09-08 10:00:00',
    provider: '上海新课'
  },
  {
    num: 2,
    name: '后视镜',
    code: 'wu-05',
    amount: '10',
    status: '空',
    lot: '1',
    warehouse: '仓库B',
    unit: '个',
    time: '2023-09-08 10:00:00',
    provider: '上海新课'
  },
  {
    num: 3,
    name: '后视镜',
    code: 'wu-05',
    amount: '10',
    status: '空',
    lot: '1',
    warehouse: '仓库B',
    unit: '个',
    time: '2023-09-08 10:00:00',
    provider: '上海新课'
  },
  {
    num: 4,
    name: '后视镜',
    code: 'wu-05',
    amount: '10',
    status: '空',
    lot: '1',
    warehouse: '仓库B',
    unit: '个',
    time: '2023-09-08 10:00:00',
    provider: '上海新课'
  },
  {
    num: 5,
    name: '后视镜',
    code: 'wu-05',
    amount: '10',
    status: '空',
    lot: '1',
    warehouse: '仓库B',
    unit: '个',
    time: '2023-09-08 10:00:00',
    provider: '上海新课'
  }
]);
const list2 = ref([
  {
    num: 1,
    name: '后视镜',
    code: 'wu-05',
    amount: '10',
    status: '空',
    lot: '1',
    warehouse: '仓库B',
    unit: '个',
    time: '2023-09-08 10:00:00',
    provider: '上海新课'
  },
  {
    num: 2,
    name: '后视镜',
    code: 'wu-05',
    amount: '10',
    status: '空',
    lot: '1',
    warehouse: '仓库B',
    unit: '个',
    time: '2023-09-08 10:00:00',
    provider: '上海新课'
  },
  {
    num: 3,
    name: '后视镜',
    code: 'wu-05',
    amount: '10',
    status: '空',
    lot: '1',
    warehouse: '仓库B',
    unit: '个',
    time: '2023-09-08 10:00:00',
    provider: '上海新课'
  },
  {
    num: 4,
    name: '后视镜',
    code: 'wu-05',
    amount: '10',
    status: '空',
    lot: '1',
    warehouse: '仓库B',
    unit: '个',
    time: '2023-09-08 10:00:00',
    provider: '上海新课'
  },
  {
    num: 5,
    name: '后视镜',
    code: 'wu-05',
    amount: '10',
    status: '空',
    lot: '1',
    warehouse: '仓库B',
    unit: '个',
    time: '2023-09-08 10:00:00',
    provider: '上海新课'
  }
]);
const loading = ref(false);
const finished = ref(false);
const onLoad = () => {
};
function back() {
  router.push({ path: "/index" })
}
function goDetail(v) {
  console.log(v.option, v.way)
  switch (true) {
    case (v.option === '库位状态' && v.way === '拉动'):
      router.push({ path: "/location" })
      break;
    case (v.option === '库位状态' && v.way === '推动'):
      router.push({ path: "/locationB" })
      break;
    case (v.option === '扫描' && v.way === '拉动'):
      router.push({ path: "/scanA" })
      break;
    case (v.option === '扫描' && v.way === '推动'):
      router.push({ path: "/scanB" })
      break;
    case (v.option === '订单' && v.way === '拉动'):
      router.push({ path: "/orderA" })
      break;
    case (v.option === '订单' && v.way === '推动'):
      router.push({ path: "/orderB" })
      break;
    case (v.option === '钢托号' && v.way === '拉动'):
      router.push({ path: "/steelA" })
      break;
    case (v.option === '钢托号' && v.way === '推动'):
      router.push({ path: "/steelB" })
      break;
    default:
      // 如果没有任何条件匹配，可以在这里处理
      console.log('没有匹配的条件');
  }
}
</script>
<style scoped>
.flex{
  display: flex;
}
.scan{
  align-items: center;
}
.kan{
  justify-content: space-between;
  align-items: center;
}
</style>
<style>
.list2 .van-cell__title span{
  font-size: 0.3rem;
}
.fz {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
}
.fz div{
  width: 100px;
  display: flex;
  font-size: 0.3rem;
  margin-right: 10px;
}
.all{
  width: 100%;
  margin: 0 auto;
  background-color: #fff;
}
.flex{
  display: flex;
  width: 100%;
  overflow: scroll;
  justify-content: space-between;
}
</style>
